import { RegularPeriod } from './masterTypes';
import { RequestSchedule } from './requestTypes';
import { OperationType } from './settingsTypes';

import { IncentiveAccount, OrderMode } from './sharedTypes';
import { VisibilityToggle, StateToggle, StateRequestToggle } from './toggles';

export enum PeriodTypes {
  None = 'None',
  Daily = 'Daily',
  Weekly = 'Weekly',
  Monthly = 'Monthly',
  Quarterly = 'Quarterly'
}

export enum GraphicsPeriodTypes {
  Hour = 'Hour',
  Day = 'Day',
  Week = 'Week',
  Fortnight = 'Fortnight',
  Month = 'Month',
  Year = 'Year'
}

export class AccountOptionsInvestor {
  InvestorStatementButton = VisibilityToggle.Default;
  MasterListButton = VisibilityToggle.Default;
  MasterList = VisibilityToggle.Default;
  CalcInvestorFeeButton = VisibilityToggle.Default;
  ChangeSlTpButton = VisibilityToggle.Default;
  InvestorOpenOrdersTab = VisibilityToggle.Default;
  InvestorHistoryTab = VisibilityToggle.Default;
  MasterOpenOrdersTab = VisibilityToggle.Default;
  MasterHistoryTab = VisibilityToggle.Default;
  InvestorWithdrawButton = VisibilityToggle.Default;
  InvestorCreditButton = VisibilityToggle.Default;
  InvestorMmDetails = VisibilityToggle.Default;
  InvestorMmBalanceDetails = VisibilityToggle.Default;
  OrdersCommissions = VisibilityToggle.Default;
  DetachAction = StateRequestToggle.Default;
  EmailNotifications = StateToggle.Default;
  DepositNotification = StateToggle.Default;
  WithdrawalNotification = StateToggle.Default;
  ConnectNotification = StateToggle.Default;
  DisconnectNotification = StateToggle.Default;
  PerformanceFeeNotification = StateToggle.Default;
  AnnualManagementFeeNotification = StateToggle.Default;
}

export enum InvestorMode {
  Normal = 0,
  Suspended = 1,
  AttemptingDetach = 2,
  Disabled = 3
}

export class IncentiveSummary {
  IncentiveFee = 0;
  ManagementFee = 0;
  AnnualManagementFee = 0;
  PerLotFee = 0;
  PerDealFee = 0;
}

export class Investor {
  Id = 0;
  Name = '';
  Username = '';
  LinkToken = '';
  Country = '';
  Phone = '';
  Email = '';
  Currency = 'USD';
  Sl = 0;
  Tp = 0;
  So = 0;
  SlPercent = 0;
  TpPercent = 0;
  SoPercent = 0;
  EquityCallLevel = 0;
  SlMode = OrderMode.Off;
  TpMode = OrderMode.Off;
  SoMode = OrderMode.Off;
  EquityCallLevelMode = OrderMode.Off;
  MasterId = 0;
  IsOwnFunds = false;
  Balance = 0;
  Investments = 0;
  Credit = 0;
  LastChargeBalance = 0;
  LastChargeTime = '';
  Equity = 0;
  EquityNoCredit = 0;
  Margin = 0;
  FreeMargin = 0;
  FreeMarginNoCredit = 0;
  MarginLevel = 0;
  AvailableFreeMargin = 0;
  AvailableFreeMarginNoCredit = 0;
  Mode = InvestorMode.Normal;
  DisablingTime = null;
  AttachTime = '';
  CreationTime = '';
  TotalProfit = 0;
  HighWaterMark = 0;
  CurrentProfit = 0;
  Profit = 0;
  IncentiveAccountsServer = '';
  IncentiveAccounts = [new IncentiveAccount()];
  IncentiveSummary = new IncentiveSummary();
  AccountOptions = new AccountOptionsInvestor();
  Server = '';
  MtServerType = '';
  StatementPeriods: Array<RegularPeriod> = [new RegularPeriod()];
  IsTestInvestor = false;
  Login = 0;
  MmName = '';
  Password = '';
  UseDefaultStatementSettings = false;
  DisconnectEquity = 0;

}

export interface IInvestorsReport {
  Name: string;
  MtServerGuid: string;
  MtLogin: number;
  Id: number;
  Mm: number;
  Balance: number;
  Equity: number;
  Credit: number;
  DisconnectEquity: number;
  Investments: number;
  Profit: number;
  TotalProfit: number;
  CreatedAt: number;  
}

export class CreateInvestor {
  Username = '';
  Password = '';
  Name = '';
  Email = '';
  Phone = '';
  Country = '';
  LinkToken = '';
  Currency = 'USD';
  Server = '';
  IncentiveAccountsServer = '';
  IncentiveAccounts?: Array<IncentiveAccount> = [new IncentiveAccount()];
  StatementPeriods?: Array<RegularPeriod> = [new RegularPeriod()];
  UseDefaultStatementSettings = true;
  Login? = 0;
}

export class UpdateInvestor {
  Username? = '';
  Name? = '';
  Password? = '';
  Email? = '';
  Country? = '';
  Phone? = '';
  LinkToken? = '';
  Mode? = InvestorMode.Normal;
  SlMode? = '';
  Sl? = 0;
  TpMode? = '';
  Tp? = 0;
  SoMode? = '';
  So? = 0;
  EquityCallLevelMode? = '';
  EquityCallLevel? = 0;
  HighWaterMark? = 0;
  MinInvestment? = 10000;
  IsTestInvestor? = false;
}

export class UpdateInvestorBalance {
  AccountId = 0;
  Amount = 0;
  Currency = '';
  Schedule? = RequestSchedule.Instant;
  Method? = '';
  Comment = '';
  CheckMargin? = true;
  OperationType? = OperationType.Balance;
}

export class UpdateInvestorBalanceTransfer {
  FromInvestorId = 0;
  ToInvestorId = 0;
  Amount = 0;
  Currency = '';
  Method? = '';
  Comment? = '';

}

export enum UpdateBalanceType {
  Deposit = 'Deposit',
  Withdrawal = 'Withdrawal'
}

export enum UpdateCreditType {
  CreditIn = 'CreditIn',
  CreditOut = 'CreditOut'
}

export class CreateInvestorFeeSetFlags {
  performanceFeeEnabled = false;
  managementFeeEnabled = false;
  annualManagementFeeEnabled = false;
  perLotDealFeeEnabled = false;
}

export class RegisterInvestor {
  Name = '';
  Username = '';
  Country = '';
  Phone = '';
  Email = '';
  Currency = 'USD';
  Investments = 0;
  Comment = '';
}

export class UpdateInvestorCredit {
  AccountId = 0;
  Amount = 0;
  Currency = '';
  Schedule? = RequestSchedule.Instant;
  Comment = '';
  Expiration? = 0;
}

export class CalcIncentiveFee {
  Ids = [0];
}

export enum LimitOrderTypes {
  Sl = 'Sl',
  Tp = 'Tp',
  So = 'So',
  Ec = 'Ec'
}

export interface IInvestorsMassAttach { 
  MasterId: number;
  InvestorIds: Array<number | string>;
}

export interface IInvestorsMassDetach { 
  InvestorIds: Array<number | string>;
}

