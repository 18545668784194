export enum UserType {
  Investor = 'Investor',
  Master = 'Master',
  Admin = 'Admin'
}

export enum OrderType {
  Open = 'Open',
  Closed = 'Closed'
}

export enum ServerType {
  MT4 = 0,
  MT5 = 1
}

export class Server {
  Guid = '';
  Name = '';
  Type = ServerType.MT4;
  TypeStr = 'MT4';
  FreeMarginCoef = 0;
  Enabled = true;
  StatusStr = '';
}

export enum BalanceOperationType {
  Connect = 'Connect',
  Disconnect = 'Disconnect',
  Deposit = 'Deposit',
  Withdraw = 'Withdraw',
  Performance = 'Performance',
  ManagementFee = 'ManagementFee',
  Commission = 'Commission',
  BalanceCorrection = 'BalanceCorrection',
  Swap = 'Swap',
  AnnualManagementFee = 'AnnualManagementFee'
}

export class PaginationInfo {
  TotalRecords = 0;
  TotalPages = 0;
  CurrentPage = 0;
  RecordsOnPage = 0;
}

export class BalanceOperation {
  MtOrderId = 0;
  Id = 0;
  Date = '';
  MasterId = 0;
  InvestorId = 0;
  Type = '';
  Amount = 0;
  Currency = 'USD';
  Comment = '';
}

export enum Language {
  EN = 'en',
  RU = 'ru'
}

export enum SummaryType {
  Deposits = 'deposits',
  Investments = 'investments',
  Profit = 'profit',
  Volumes = 'volumes',
  Investors = 'investors'
}

export class Summary {
  PeriodStartDate = '';
  PeriodEndDate = '';
  Value = 0;
}

export enum GraphicsPeriodTypes {
  Hour = 'Hour',
  Day = 'Day',
  Week = 'Week',
  Fortnight = 'Fortnight',
  Month = 'Month',
  Year = 'Year'
}

export enum GraphicsPeriodTypesExt {
  Hour = 'Hour',
  Day = 'Day',
  Week = 'Week',
  Fortnight = 'Fortnight',
  Month = 'Month',
  Year = 'Year',
  All = 'All'
}

export enum AnnualManagementFeeMode {
  ByCurrentBalance = 'ByCurrentBalance',
  ByLastChargeBalance = 'ByLastChargeBalance'
}

export class IncentiveAccount {
  Login = 0;
  ServerName? = '';
  IncentiveFee: any = 0;
  ManagementFee: any = 0;
  AnnualManagementFee: any = 0;
  PerLotFee: any = 0;
  PerDealFee: any = 0;
  Mode: any = AnnualManagementFeeMode.ByCurrentBalance;
}

export enum PerLotDealFeeMode {
  PerLot = 'PerLot',
  PerDeal = 'PerDeal'
}

export class FeeAccountSet {
  [index: string]: any;

  Login = '';
  IncentiveFee = 0.0;
  ManagementFee = 0.0;
  AnnualManagementFee = 0.0;
  AnnualManagementFeeMode = AnnualManagementFeeMode.ByCurrentBalance;
  PerLotDealFee = 0.0;
  PerLotDealFeeMode = PerLotDealFeeMode.PerDeal;
  Valid = true;
}

export class ChangeAccountModeRequest {
  Mode = 0;
}

export enum OrderMode {
  Off = 'Off',
  Percent = 'Percent',
  Money = 'Money',
  FromMM = 'FromMM',
  Default = 'Default'
}

export interface ConfigFlags {
  hideRegisterButton: boolean;
  showMmRegistrator: boolean;
  // TODO PAMM-2341 
  // showRequestTab: boolean;
  RequestTab: boolean;
  showOptionLockedWithdrawalsAndDisconnections: boolean;
  showMMsGraphsFromInvestors: boolean;
  showMmEmailToInvestor: boolean;
  showMmInvestorCount: boolean;
}

export interface SharedConfig {
  CountCreditInAllocation: boolean;
  CountCreditInWithdrawal: boolean;
  CountCreditInRiskControl: boolean;
  RequestTab: boolean;
}

export enum BalanceOperationTypesMasters {
  Connect = 'Connect',
  Disconnect = 'Disconnect',
  Deposit = 'Deposit',
  Withdraw = 'Withdraw',
  Performance = 'Performance',
  ManagementFee = 'ManagementFee',
  AnnualManagementFee = 'AnnualManagementFee',
  Commission = 'Commission',
  DealCommission = 'DealCommission',
  DealTax = 'DealTax',
  Swap = 'Swap',
  BalanceCorrection = 'BalanceCorrection',
  Credit = 'Credit',
  ProfitCorrection = 'Profit Correction',
  CreditCorrection = 'Credit Correction',
  CreditConnect = 'Credit Connect',
  CreditDisconnect = 'Credit Disconnect'

}

export enum BalanceOperationTypesInvestors {
  Deposit = 'Deposit',
  Withdraw = 'Withdraw',
  Performance = 'Performance',
  ManagementFee = 'ManagementFee',
  AnnualManagementFee = 'AnnualManagementFee',
  Commission = 'Commission',
  DealCommission = 'DealCommission',
  DealTax= 'DealTax',
  Swap = 'Swap',
  BalanceCorrection = 'BalanceCorrection',
  Credit = 'Credit',
  ProfitCorrection = 'Profit Correction',
  CreditCorrection = 'Credit Correction'
}
