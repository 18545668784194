import { Dispatch } from 'redux';
import Errors from '../../../shared/validation/errors';
import headersBuilder, { Method } from '../../../utils/request';
import { GraphicsPeriodTypes } from '../../../types/masterTypes';
import { Summary } from '../../../types/sharedTypes';
import { getFromDateForRequest, getToDateForRequest } from '../../../utils/date-time';

export const FETCH_PROFIT = 'FETCH_PROFIT';
export const FETCH_PROFIT_PER_PERIOD = 'FETCH_PROFIT_PER_PERIOD';
export const FETCH_ARRAY_OF_PROFITS = 'FETCH_ARRAY_OF_PROFITS';
export const FETCH_PROFIT_OVERALL = 'FETCH_PROFIT_OVERALL';

export type FetchProfitAction = {
  type: string;
  profitFortnight: Array<Summary>;
};

export type FetchArrayOfProfitsAction = {
  type: string;
  masterId: number;
  profitFortnight: Array<Summary>;
};

const fetchProfit = (profitFortnight: Array<Summary>): FetchProfitAction => {
  return {
    type: FETCH_PROFIT,
    profitFortnight
  };
};

const fetchProfitPerPeriod = (profitFortnight: Array<Summary>): FetchProfitAction => {
  return {
    type: FETCH_PROFIT_PER_PERIOD,
    profitFortnight
  };
};

const fetchProfitOverall = (profitFortnight: Array<Summary>): FetchProfitAction => {
  return {
    type: FETCH_PROFIT_OVERALL,
    profitFortnight
  };
};

const fetchArrayOfProfits = (masterId: number, profitFortnight: Array<Summary>): FetchArrayOfProfitsAction => {
  return {
    type: FETCH_ARRAY_OF_PROFITS,
    masterId,
    profitFortnight
  };
};

const fetchProfitAsync = (api: string, id: number, period: GraphicsPeriodTypes, dateFrom: Date, dateTo: Date, calcMethod?: string, isArray?: boolean) => {
  let query = `${id}?period=${period}`;

  const dateTimeDateFrom = getFromDateForRequest(dateFrom);
  const dateTimeDateTo = getToDateForRequest(dateTo);

  if (dateFrom) query += `&from=${dateTimeDateFrom}`;
  if (dateTo) query += `&to=${dateTimeDateTo}`;
  if (calcMethod) query += `&calcMethod=${calcMethod}`;

  return (dispatch: Dispatch): void => {
    fetch(`${api}/summary/profit/${query}`, headersBuilder(Method.GET))
    .then((response: Response) => Errors.checkResponseForErrors(response, api))
    .then((data: any) => {
      if (calcMethod === 'running') {
        dispatch(fetchProfitPerPeriod(data));
      } 
      else if (calcMethod === 'RunningWithPreviousValue') {
        dispatch(fetchProfitOverall(data));
      } 
      else {
        dispatch(fetchProfit(data));
      }

      if (isArray) {
        dispatch(fetchArrayOfProfits(id, data));
      }
    })
    .catch((error: Error) => Errors.throwErrorMessage(error));
  }
};

export default fetchProfitAsync;
